<template lang="pug">
  v-list-tile(
    :class="tileClass(item)"
    avatar
  ).list-item
    v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
      img(:src="item.dashboard.image.url")
    v-list-tile-content(@click="select(item)")
      v-list-tile-title
        v-layout(row)
          v-flex(xs6) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} -
            span.font-weight-bold  {{item.location.template.name}}
          v-flex(xs6)
            v-layout(row)
              v-flex.pink--text(xs12 v-if="item.minutes.override")
                | minutes: {{item.minutes.override}}
              v-flex(xs12 v-else)
                | minutes: {{item.minutes.default}}
      v-list-tile-sub-title
        v-layout(row)
          v-flex(xs6)
            PiSubtitle(:item='item')
</template>
<script>
import PiSubtitle from './pi-subtitle'
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['item'],
  components: {
    PiSubtitle,
  },
  methods: {
    tileClass (item) {
      if (item.dashboard.isPressCheck) {
        return 'highlight-presscheck'
      }
      const ddDate = item.dates.dropDeadOverride
        ? item.dates.dropDeadOverride
        : item.dates.dropDead
      const isDateWarning = ddDate ? ddDate < item.dates.schedule : false
      if (isDateWarning) {
        return 'highlight-warning'
      }
    },
    select (item) {
      this.$router.push({ path: '/dashboards/schedule/' + item._id })
    }


  }
}
</script>

<style lang="stylus" scoped>
.highlight-warning
  background-color #FFCDD2
.highlight-presscheck
  background-color #C8E6C9
</style>
