<template lang="pug">
div
  v-toolbar(flat fixed color='white')
    v-btn(
      @click='toggleDrawer'
      small
      flat
      icon
      )
      v-icon() menu
    v-toolbar-title.display-1.font-weight-bold profit last 30 days
    v-spacer

</template>
<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('scheduling', ['priorityToggle', 'teamToggle', 'progressToggle']),
  },
  methods: {
    toggleDrawer () {
      this.$store.dispatch('core/toggleDrawer')
    },

    togglePriority () {
      this.priorityToggle = !this.priorityToggle
      this.teamToggle = false
    },
    toggleTeam () {
      this.teamToggle = !this.teamToggle
      this.priorityToggle = false
    },

  },
}
</script>
